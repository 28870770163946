import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from '@remix-run/react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { LegalDisclaimer } from '../common/Utilities';
import { Loading } from '../Loading';
import { buildSearchParamsWithRedirectToAsString, usePostLogin } from './hooks';
import { LoginHeader, LoginModalLayoutV2 } from './LoginModalLayout';

type FormData = {
  firstName: string;
  email: string;
};

export const GuestQuickSignupModal = (props: {
  orgId: string;
  setStep: (i: number) => void;
}): JSX.Element => {
  const { setStep } = props;
  const postLogin = usePostLogin();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      firstName: '',
      email: '',
    },
  });

  const {
    call: submit,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (data: FormData) => {
    try {
      const resp = await apiService.auth.guestSignup({
        firstName: data.firstName,
        email: data.email,
        orgId: props.orgId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return resp.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          const search = buildSearchParamsWithRedirectToAsString(
            window.location.href,
            { email: data.email, 'auto-submit': true }
          );
          navigate({ pathname: '/login', search });
          return null;
        }
      }
      throw error;
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    const resp = await submit(data);
    if (!resp) return;
    postLogin(resp);
    setStep(1);
  });

  return (
    <LoginModalLayoutV2>
      <div className='relative text-white transform translate-y-[10%] w-full flex justify-center'>
        <LoginHeader />
        <div
          className={`w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    pt-6 pb-6 px-5 lg:px-15 gap-5`}
        >
          <div className='text-tertiary text-center text-3.5xl pt-4'>
            Join the Fun!
          </div>
          <div className='w-80 lg:w-100 h-full flex flex-col justify-between items-center'>
            <form
              onSubmit={onSubmit}
              className='w-full h-full flex flex-col items-center gap-4'
            >
              <div className='w-full flex flex-col gap-4'>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: 'First name is required',
                    minLength: {
                      value: 3,
                      message: 'First name must be 3 or more characters',
                    },
                    maxLength: {
                      value: 25,
                      message: 'First name must be 25 or less characters',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <label>
                      <div className='font-bold mb-2'>First Name</div>
                      <input
                        {...field}
                        className={`${
                          fieldState.error ? 'field-error' : 'field'
                        } w-full h-12.5 mb-1`}
                        type='text'
                        placeholder='First Name'
                        autoComplete='off'
                        data-1p-ignore
                      />
                      <ErrorMessage
                        errors={errors}
                        name='firstName'
                        render={({ message }) => (
                          <div className='text-sms text-red-002 mt-1'>
                            {message}
                          </div>
                        )}
                      />
                    </label>
                  )}
                />
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: 'Email is required' }}
                  render={({ field, fieldState }) => (
                    <label>
                      <div className='font-bold mb-2'>
                        Enter your work email address
                      </div>
                      <input
                        {...field}
                        className={`${
                          fieldState.error ? 'field-error' : 'field'
                        } w-full h-12.5 mb-1`}
                        type='email'
                        placeholder='Email Address'
                        autoComplete='off'
                        data-1p-ignore
                      />
                      <ErrorMessage
                        errors={errors}
                        name='email'
                        render={({ message }) => (
                          <div className='text-sms text-red-002 mt-1'>
                            {message}
                          </div>
                        )}
                      />
                    </label>
                  )}
                />
              </div>

              <button
                type='submit'
                className='btn-primary w-full h-12.5 font-bold flex items-center justify-center gap-2'
                disabled={isRunning || Object.keys(errors).length > 0}
              >
                {isRunning && <Loading text='' />}
                <div>Join the Experience</div>
              </button>
            </form>
            {error && (
              <div className='mt-2 w-full text-sms text-red-002 text-center'>
                {err2s(error)}
              </div>
            )}
          </div>
          <div className='mt-2 flex flex-col items-center justify-center gap-1'>
            <LegalDisclaimer text='By joining Luna Park' />
          </div>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
};
