import { useCallback, useEffect, useState } from 'react';

import { useVenueAnalytics } from '../../analytics/venue';
import logo from '../../assets/img/logo-text.svg';
import { GuestQuickSignupModal } from '../../components/Access/GuestQuickSignupModal';
import { GuestLoginModal } from '../../components/Access/LoginModalGuest';
import { Modal } from '../../components/common/Modal';
import {
  DeviceCheck,
  DeviceCheckContextProvider,
} from '../../components/Device/Check';
import { useUserContext } from '../../components/UserContext';
import {
  useMarkVenueInitCompleted,
  useNavigateToVenueAtCapacity,
  useVenueId,
} from '../../components/Venue';
import { assertExhaustive } from '../../utils/common';
import { useCanJoinGetter, VenueCapacityCheck } from './VenueCapacityCheck';

export type OnboardingStrategy =
  | 'guest-onboarding-default'
  | 'guest-onboarding-conversion'
  | 'guest-onboarding-register';

function DeviceCheckModal(props: {
  setStep: (i: number) => void;
}): JSX.Element {
  const { setStep } = props;
  const [loaded, setLoaded] = useState(false);
  const ctx = useUserContext();
  const venueId = useVenueId();
  const getCanJoin = useCanJoinGetter();
  const navigateToVenueAtCapacity = useNavigateToVenueAtCapacity();
  const analytics = useVenueAnalytics();

  const onClick = async () => {
    analytics.trackDeviceCheckCompleted({ venueId });

    // venue capacity check – check that we can actually join.
    const canJoin = await getCanJoin();
    if (!canJoin) {
      navigateToVenueAtCapacity();
      return;
    }

    ctx.updateUserStates({ joined: true });
    setStep(2);
  };

  const handleLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <Modal>
      <div className='w-176 h-121 flex flex-col bg-modal rounded-xl text-white relative px-8 pt-12 pb-8 items-center'>
        <img src={logo} className='w-63 absolute -top-27' alt='logo' />
        <DeviceCheckContextProvider>
          <div className='flex flex-col items-center'>
            <p className='mb-6'>
              Welcome! Check your settings before you join.
            </p>
            <DeviceCheck onLoaded={handleLoaded} venueId={venueId} />
            <div className='my-5 text-sm'>
              Please use headphones for the best experience.
            </div>
            <button
              type='button'
              className='btn-primary w-40 h-10 text-base'
              data-testid='device-check-continue'
              onClick={onClick}
              disabled={!loaded}
            >
              {loaded ? 'Next' : 'Loading'}
            </button>
          </div>
        </DeviceCheckContextProvider>
      </div>
    </Modal>
  );
}

export function Onboarding(props: {
  strategy: OnboardingStrategy;
  orgId: string | null;
  onComplete: () => void;
}) {
  const { strategy, orgId, onComplete } = props;
  const [step, setStep] = useState(0);
  useMarkVenueInitCompleted(true);

  useEffect(() => {
    if (step >= 2) {
      onComplete();
    }
  }, [step, onComplete]);

  switch (step) {
    case 0:
      if (strategy === 'guest-onboarding-default') {
        return <GuestLoginModal conversion={false} setStep={setStep} />;
      } else if (strategy === 'guest-onboarding-conversion') {
        return <GuestLoginModal conversion={true} setStep={setStep} />;
      } else if (strategy === 'guest-onboarding-register') {
        if (!orgId) {
          return <GuestLoginModal conversion={false} setStep={setStep} />;
        }
        return <GuestQuickSignupModal orgId={orgId} setStep={setStep} />;
      } else {
        assertExhaustive(strategy);
        return null;
      }
    case 1:
      return (
        <VenueCapacityCheck>
          <DeviceCheckModal setStep={setStep} />
        </VenueCapacityCheck>
      );
    default:
      return null;
  }
}
