// Slack uses placholder icons for users who don't have the customized one.
// There is no better way to detect if the icon is a placeholder or not.
// The list here is based on my observation.
const DENY_ICON_HOST_LIST = ['gravatar.com', 'wp.com'] as const;

export function userIconRenderable(
  icon: string,
  denylist = DENY_ICON_HOST_LIST
) {
  if (icon === '') return false;
  try {
    const url = new URL(icon);
    return !denylist.some((host) => url.host.endsWith(host));
  } catch (error) {
    return false;
  }
}
