import { useLayoutEffect } from 'react';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const maxWidth = getFeatureQueryParamNumber('small-viewport-hack-max-width-px');
const maxHeight = getFeatureQueryParamNumber(
  'small-viewport-hack-max-height-px'
);
const fontSizePx = getFeatureQueryParamNumber(
  'small-viewport-hack-font-size-px'
);

const LP_BASE_FONT_SIZE_CSS_VAR = '--lp-base-font-size';

// NOTE: this is in a separate component to prevent causing the parent component
// to re-render needlessly. This hook has no return value and is
// side-effects-only, so there's no reason to force the parent to check for
// changes.
export function ScaleFontSizeForSmallWindowsHack() {
  useScaleFontSizeForSmallWindowsHack();
  return null;
}

function useScaleFontSizeForSmallWindowsHack(): void {
  const win = useWindowDimensions();
  useLayoutEffect(() => {
    if (win.width > maxWidth && win.height > maxHeight) return;

    const root = document.body.parentElement;
    if (!root) return;

    root.style.setProperty(LP_BASE_FONT_SIZE_CSS_VAR, `${fontSizePx}px`);
    return () => {
      if (!root) return;
      root.style.setProperty(LP_BASE_FONT_SIZE_CSS_VAR, null);
    };
  }, [win.width, win.height]);
}
