import { useNavigate } from '@remix-run/react';
import { type ReactNode, useEffect, useState } from 'react';

import { buildSearchParamsWithRedirectToAsString } from '../../components/Access/hooks';
import {
  isGuest,
  useIsUserLoaded,
  useRemoveStaffFlag,
  useUpdateUsername,
  useUser,
  useUserContext,
} from '../../components/UserContext';
import { useVenue, useVenueOwner } from '../../components/Venue/VenueProvider';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
import { safelyAppendStaffFlag } from '../../types';
import { assertExhaustive } from '../../utils/common';
import { Onboarding, type OnboardingStrategy } from './Onboarding';

const log = logger.scoped('onboarding');

type State =
  | 'none'
  | 'login-required'
  | 'preparing'
  | 'done'
  | OnboardingStrategy;

export function UserRequired(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const { resetUser } = useUserContext();
  const user = useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const isGuestUser = isGuest(user);
  const [venue] = useVenue();
  const venueOwner = useVenueOwner();
  const [state, setState] = useState<State>('none');
  const navigate = useNavigate();
  const updateUsername = useUpdateUsername();
  const removeStaffFlag = useRemoveStaffFlag();

  const resolveGuestConfig = useLiveCallback(
    (): {
      enabled: boolean;
      onboarding: OnboardingStrategy | null;
    } => {
      const override = getFeatureQueryParamArray('guest-users');
      switch (override) {
        case 'enabled':
          return { enabled: true, onboarding: null };
        case 'disabled':
          return { enabled: false, onboarding: null };
        case 'default':
          return { enabled: venue.guestEnabled, onboarding: null };
        case 'conversion':
          return { enabled: true, onboarding: 'guest-onboarding-conversion' };
        case 'register':
          return { enabled: true, onboarding: 'guest-onboarding-register' };
        case 'venue-register':
          if (venue.guestEnabled) {
            return { enabled: venue.guestEnabled, onboarding: null };
          }
          return { enabled: true, onboarding: 'guest-onboarding-register' };
        default:
          assertExhaustive(override);
          return { enabled: false, onboarding: null };
      }
    }
  );

  const init = useLiveCallback((userId: string) => {
    const { enabled: guestEnabled, onboarding } = resolveGuestConfig();
    if (isGuestUser && !guestEnabled) {
      log.info('reset guest user');
      resetUser();
      return;
    }
    if (!userId) {
      if (guestEnabled) {
        setState(onboarding ?? 'guest-onboarding-default');
      } else {
        navigate({
          pathname: '/login',
          search: buildSearchParamsWithRedirectToAsString(window.location.href),
        });
        setState('login-required');
      }
    } else if (getFeatureQueryParam('cohost')) {
      // coming in as a cohost...clear out any staff flags
      setState('preparing');
      removeStaffFlag().finally(() => setState('done'));
    } else {
      setState('preparing');
      const newName = safelyAppendStaffFlag(
        user,
        getFeatureQueryParam('ferris-wheel')
      );
      if (newName === user.username) {
        setState('done');
        return;
      }
      updateUsername(newName).finally(() => setState('done'));
    }
  });

  const reset = useLiveCallback(() => setState('none'));

  useEffect(() => {
    if (!isUserLoaded) return;
    init(user.id);
    return () => reset();
  }, [init, isUserLoaded, reset, user.id]);

  switch (state) {
    case 'none':
    case 'preparing':
    case 'login-required':
      return null;
    case 'guest-onboarding-default':
    case 'guest-onboarding-conversion':
    case 'guest-onboarding-register':
      return (
        <Onboarding
          strategy={state}
          orgId={venueOwner.orgId}
          onComplete={() => setState('done')}
        />
      );
    case 'done':
      return <>{props.children}</>;
    default:
      assertExhaustive(state);
      return null;
  }
}
